/** @kpsl
 * @copyright KAPPSELL Kamil Kamiński
 * @created 2018-08-13
 *
 * jQuery plugin which converts <select> to HTML trigger element with dropdown menu (like bootstrap dropdown)
 * Default settings optimized for Bootstrap v.4.1.2
 * 
 * SUPPORTS:
 * - select[multiple]
 * - option[hidden]
 * - optgroup[label]
 *
 * KNOWN ISSUES
 * - two or more selects can't be on the same DOM lvl
 *
 * TODO
 * - assign selected class to selected item
 */

(function( $ ) {
  $.fn.bootstrapSelectToDropdown = function(options){ return this.each(function(){
    var settings = $.extend( {}, $.fn.bootstrapSelectToDropdown.defaults, $(this).data(), options);  
    var select = this;
    var label = function(){
      return $.map(
          $(select).find(':selected'),
          function(value, i){ return $(value).text(); }
        ).join(', ') || $(select).find('[hidden]').text(); 
    }; // return every option:selected text separated by ", " or option[hidden] if there is none :selected
    var isMultiple = $(select).is('[multiple]');
    var isSelected = function(dropdownItem){
      return isMultiple ?
        $(select).val().indexOf($(dropdownItem).data('value')) !== -1 :
        $(select).val() === String($(dropdownItem).data('value'));
    };
    var hasOptGroups = $(select).find('optgroup[label]').length > 0 ? true : false;

    var trigger = $(settings.triggerHTML).addClass(settings.triggerClass).text(label);
    var dropdown = $(settings.dropdownHTML).addClass(settings.dropdownClass);

    // every option except [hidden] convert to HTML
    $(select).find('option:not([hidden])').each(function(index){
      var dropdownItem = $(settings.dropdownItemHTML) 
        .addClass(settings.dropdownItemClass) 
        .attr('data-value', $(this).val())
        .text($(this).text())
        .click(function(e){
          e.preventDefault();

          if(isMultiple){ // values as array of values
            var values = $(select).val();
            $(this).toggleClass(settings.dropdownItemSelectedClass);

            if($(this).hasClass(settings.dropdownItemSelectedClass)){ // class just added, so it was not in values array - add it
              values.push($(this).data('value'));
            }else{  // class just removed, so it was already in values array - remove it
              values.splice(values.indexOf($(this).data('value')), 1);
            }
            $(select).val(values);
          }else{ // value as single value
            var value = $(this).data('value');
            $(select).val(value);
            dropdown.find('.' + settings.dropdownItemSelectedClass).removeClass(settings.dropdownItemSelectedClass);
            $(this).addClass(settings.dropdownItemSelectedClass);
          }
          $(select).change();
        });
      /**
       * if select has optgroups and current option is first child of optgroup with label,
       * then place header before HTML option in dropdown menu
       * @todo make it optional
       */
      if(hasOptGroups && $(this).is('optgroup[label] option:first-child')){
        var header = $(settings.optGroupHeaderHTML)
          .addClass(settings.optGroupHeaderClass)
          .text($(this).parent().attr('label'));
        dropdown.append(header);
      }
      
      if(isSelected(dropdownItem)){ dropdownItem.addClass(settings.dropdownItemSelectedClass); }
      
      dropdown.append(dropdownItem);
    });

    // assign new label to trigger after select change
    $(select).change(function(){ trigger.text(label); });

    trigger.insertAfter($(select));
    dropdown.insertAfter(trigger);

    if(isMultiple){ 
      // prevent hiding dropdown menu on option select
      dropdown.click(function(e){ e.stopPropagation(); });
    }
    $(select).trigger('shown.cbs.selecttodropdown', [dropdown]);
  }); };
  $.fn.bootstrapSelectToDropdown.defaults = {
    triggerHTML: '<button data-toggle="dropdown"/>',
    triggerClass: 'btn btn-primary dropdown-toggle',
    dropdownHTML: '<div/>',
    dropdownClass: 'dropdown-menu',
    dropdownItemHTML: '<a href="#" />',
    dropdownItemClass: 'dropdown-item',
    dropdownItemSelectedClass: 'selected',
    optGroupHeaderHTML: '<div />',
    optGroupHeaderClass: 'dropdown-header'
  };
})( jQuery );