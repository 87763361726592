/**
 * Plugin for converting group of buttons and input into counter
 * Required: group of 3 elements:
 *  - input
 *  - [data-action="increase"]
 *  - [data-action="decrease"]
 * @copyright KAPPSELL Kamil Kamiński
 */
(function( $ ) {
  $.fn.bootstrapCounter = function(options){ return this.each(function(){
    var counter = $(this);
    var increaseButton = counter.find('[data-action="increase"]');
    var decreaseButton = counter.find('[data-action="decrease"]');
    var input = counter.find('input');
    var getCurrentValue = function() { var v = input.val(); return isNaN(v) || v === '' ? 1 : parseFloat(v); };
    
    increaseButton.off().click(function(e){ e.preventDefault(); input.val(getCurrentValue() + 1); input.change(); });
    decreaseButton.off().click(function(e){ e.preventDefault(); input.val(getCurrentValue() - 1); input.change(); });
    input.change(function(){ 
      var value = $(this).val();      
      if(value <= 1 || isNaN(value) || value === ''){ 
        decreaseButton.attr('disabled', 'disabled').addClass('disabled'); 
        $(this).val(1);
      } else { 
        decreaseButton.removeAttr('disabled').removeClass('disabled'); 
      }
    });
  }); };
})( jQuery );