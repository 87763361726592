$(window).bind("ajaxSend", function() {
  $('#komentarze').stop(true,true).show().fadeIn(333);
});

$(window).bind("ajaxError", function() {
  $('#komentarze').stop(true,true).fadeOut(333);
});

$(window).bind("ajaxSuccess", function() {
  $('#komentarze').stop(true,true).fadeOut(333);
  //dla IE6
  if (document.recalc) document.recalc();
  //console.log('recalc');
});

		
/* funkcja generuje nowy adres z hashem - # */
function link(adres) {	
	/*var wzorzec= /\#[a-z]{0,}/i;*/
	var wzorzec= /\#[\S]{0,}/i;
	document.location.href = document.location.href.replace(wzorzec,"") + adres;
}

/* ================================= */
/* PO ZAŁADOWANIU DOM                */
/* ================================= */

$(function() {

	


	//$('#wyszukiwanie-zaawansowane').css('display','none');
		var x;
		var y;
		var z;
		x = $('#wyszukiwanie-zaawansowane').css('display');
		if ( x == 'block')  {z = 'url('+sciezka+'icons/less.png)'}
		if ( x == 'none') {z = 'url('+sciezka+'icons/more.png)'}
		$('#wyszukiwanie-toogle').css('background-image', z);
	
	$('#wyszukiwanie-toogle').click(
	function() {
		var x;
		var y;
		var z;
		x = $('#wyszukiwanie-zaawansowane').css('display');
		if ( x == 'none')  {y = 'block'; z = 'url('+sciezka+'icons/less.png)'}
		if ( x == 'block') {y = 'none'; z = 'url('+sciezka+'icons/more.png)'}
		$('#wyszukiwanie-zaawansowane').css('display', y);
		$('#wyszukiwanie-toogle').css('background-image', z);
	});

/* PRZYPOMNIENIE HASŁA */

	$('#przypomnienie-form').css('display','none');
	$('#przypomnienie-toogle').css('background-image','url('+sciezka+'icons/more.png)');
	$('#przypomnienie-toogle').click(
	function() {
		var x;
		var y;
		var z;
		x = $('#przypomnienie-form').css('display');
		if ( x == 'none')  {y = 'block'; z = 'url('+sciezka+'icons/less.png)'}
		if ( x == 'block') {y = 'none'; z = 'url('+sciezka+'icons/more.png)'}
		$('#przypomnienie-form').css('display', y);
		$('#przypomnienie-toogle').css('background-image', z);
	});


});


